import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  styled,
  Tooltip,
  Stack,
  Typography,
  Link,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridFooterContainer,
  GridPagination,
  GridRowParams,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import AssignmentLateTwoToneIcon from "@mui/icons-material/AssignmentLateTwoTone";
import AssignmentTurnedInTwoToneIcon from "@mui/icons-material/AssignmentTurnedInTwoTone";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

import EditNoteIcon from "@mui/icons-material/EditNote";
import VerifiedIcon from "@mui/icons-material/Verified";
import JBWAgreementsDetailDialog from "./JBWAgreementsDetailDialog";
import moment from "moment";
import InvoiceDetailsDialog from "./InvoiceDetailsDialog";
import JBWClaimsIframDialog from "./JBWClaimsIframDialog";
import { envConfig } from "../services/configs/envConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteConfirmDialog } from "./EquipmentsTable";
import { rejectClaim } from "../services/ClaimsServices";
import { AuthContext } from "../AuthContext";
import JBWDateLabel from "./JBWDateLabel";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Active": {
    backgroundColor: "#71d68e",
    "&:hover": {
      backgroundColor: "#4ECC72",
    },
  },
  "& .super-app-theme--InActive": {
    backgroundColor: "#e4c553",
    "&:hover": {
      backgroundColor: "#DEB729",
    },
  },
}));

const ClaimsGrid = ({
  claimList,
  showDealer = false,
  showLoading = false,
  onRejectClaim,
  onVerifyClaim,
  onUpdateClaim,
  tenant,
}) => {
  const [selectedAgreementId, setSelectedAgreementId] = useState();
  const [openAgreementDetailDialog, setOpenAgreementDetailDialog] =
    useState(false);
  const { user } = useContext(AuthContext);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [openInvoiceDetailDialog, setOpenInvoiceDetailDialog] = useState(false);
  const [claimAgreementId, setClaimAgreementId] = useState();
  const [claimDealerId, setClaimDealerId] = useState();
  const [selectedClaim, setSelectedClaim] = useState();
  const [claimsList, setClaimsList] = useState([]);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [openJBWIframeClaimDialog, setOpenJBWIframeClaimDialog] =
    useState(false);

  const [selectedClaims, setSelectedClaims] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [claimToDelete, setClaimToDelete] = useState(null);
  const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "",
      width: 45,
      sortable: false,
      renderCell: (params) => {
        const claimId = params.row.jbwClaimId;
        const bgColor =
          claimId === null || claimId === "" ? "#DEB729" : "#4ECC72";
        const iconDisaply =
          claimId === null || claimId === "" ? (
            <AssignmentLateTwoToneIcon />
          ) : (
            <AssignmentTurnedInTwoToneIcon />
          );
        return <Avatar sx={{ bgcolor: bgColor, m: 1 }}>{iconDisaply}</Avatar>;
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "jbwAgreementId",
      headerName: "JBW Agreement Id",
      width: 180,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const agreementLink = params.row.agreementlink;
        const agreementPurchaseDate = params.row.agreementPurchaseDate
          ? moment(params.row.agreementPurchaseDate).format("MM/DD/YYYY")
          : "NA";
        const warrantyStartDate = params.row.warrantyStartDate
          ? moment(params.row.warrantyStartDate).format("MM/DD/YYYY")
          : "NA";
        const warrantyEndDate = params.row.warrantyEndDate
          ? moment(params.row.warrantyEndDate).format("MM/DD/YYYY")
          : "NA";
        const infoItems = [
          `Agreement Purchase Date : ${agreementPurchaseDate}`,
          `Warranty Start Date : ${warrantyStartDate}`,
          `Warranty End Date :${warrantyEndDate}`,
        ];
        const tip = infoItems.join("\n");
        return (
          <>
            {agreementLink ? (
              <Tooltip
                title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
              >
                {/* <span className="table-cell-trucate">{params.value}</span> */}
                <Link className="table-cell-trucate" underline="always">
                  {params.value}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip
                title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
              >
                <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "productCode",
      headerName: "Plan/Invoice Id",
      width: showDealer ? 120 : 140,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const proCode = params.row.productCode;
        const poNumber = params.row.poNumber;
        const invoiceId = params.row.invoiceId;
        return (
          <Stack>
            <Typography variant="string" component="span">
              {proCode}
            </Typography>
            <Typography variant="string" component="span">
              <Link className="table-cell-trucate" underline="always">
                {poNumber ? poNumber : invoiceId}
              </Link>
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "serviceStartDate",
      headerName: "Invoice Date",
      width: showDealer ? 100 : 105,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        if (param.value === null) return "";
        return (
          <JBWDateLabel inputDate={param.value} dateFormat="MMM.DD,YYYY" />
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Details",
      width: showDealer ? 200 : 210,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const custName = params.row.customerName;
        const loc = params.row.location;
        return (
          <Stack>
            <Typography variant="string" component="span">
              {custName}
            </Typography>
            <Typography variant="string" component="span">
              {loc}
            </Typography>
          </Stack>
        );
      },
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   width: showDealer ? 200 : 210,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "jbwClaimId",
      headerName: "Claim Id",
      width: showDealer ? 100 : 110,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceSummary",
      headerName: "Invoice Summary",
      width: showDealer ? 190 : 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      width: showDealer ? 140 : 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 80,
      headerAlign: "center",
      align: "center",
      getActions: (params) => {
        //console.log("params", params.row);
        const isClaimFiled = params.row.isClaimFiled;
        let cellItems = [];
        //isreadyfor jwd = 0
        if (isClaimFiled === 0) {
          cellItems.push(
            <GridActionsCellItem
              icon={<EditNoteIcon fontSize="medium" />}
              label="Create Claim"
              className="textPrimary"
              onClick={handleCreateClaim(params)}
              color="secondary"
              title="Create Claim"
              disabled={isAnyRowSelected}
            />
          );
          cellItems.push(
            <GridActionsCellItem
              icon={<UnpublishedIcon fontSize="medium" />}
              label="Reject Claim"
              className="textPrimary"
              onClick={handleRejectClaim(params)}
              color="error"
              title="Reject Claim"
              disabled={isAnyRowSelected}
            />
          );
        } else {
          cellItems.push(
            <GridActionsCellItem
              icon={<VerifiedIcon fontSize="medium" />}
              label="Check Claim Status"
              className="textPrimary"
              onClick={handleVerifyClaim(params)}
              color="primary"
              title="Check Claim Status"
              disabled={isAnyRowSelected}
            />
          );
        }
        // cellItems.push(
        //   <GridActionsCellItem
        //     icon={<DeleteIcon fontSize="medium" />}
        //     label="Delete Claim"
        //     className="textPrimary"
        //     onClick={handleDeleteClaim(params)}
        //     color="error"
        //     title="Delete Claim"
        //   />
        // );

        return cellItems;
      },
      sortable: false,
    },
  ];

  if (showDealer) {
    columns.splice(1, 0, {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 90,
      sortable: false,
      headerAlign: "center",
      align: "center",
    });
  }

  // const handleEdit = (param, optype) => () => {
  //   console.log("Edit clicked ", param.row);
  //   //openEquimentDialog(param.row, optype);
  // };

  const handleMultiDelete = () => {
    setClaimToDelete(selectedClaims);
    setDeleteDialogOpen(true);
    // setClaimsList((prev) => prev.filter((claim) => !selectedClaims.includes(claim.id)));
    console.log(selectedClaims);
    // setSelectedClaims([]);
  };

  const handleRowSelection = (selectedIds) => {
    const selectedRows = claimsList.filter((claim) =>
      selectedIds.includes(claim.id)
    );
    setSelectedClaims(selectedRows);
    setIsAnyRowSelected(selectedIds.length > 0);
    console.log("Selected Rows Data: ", selectedRows);
    // Now you have the selected row data; you can use it as needed.
  };

  const handleRejectClaim = (params) => () => {
    setClaimToDelete(params.row);
    setSelectedClaims([params.row]);
    setDeleteDialogOpen(true);
    //onRejectClaim(params.row);
  };
  const handleVerifyClaim = (params) => () => {
    onVerifyClaim(params.row);
  };

  const handleCellClick = (params) => {
    //console.log("cell click event :", params);
    const columnName = params.field;
    const cellValue = params.value;
    //console.log(`Selected column : ${columnName} and cell value ${cellValue}`);
    if (columnName === "jbwAgreementId") {
      //setSelectedAgreementId(cellValue);
      //setOpenAgreementDetailDialog(true);
      const agreementLink = params.row.agreementlink;
      if (!agreementLink) return;
      window.open(agreementLink, "_blank");
    } else if (columnName === "productCode") {
      //console.log("selected invoice id", cellValue);
      //setSelectedInvoiceId(params.row.invoiceId);
      //setOpenInvoiceDetailDialog(true);
      const invoiceUrl = `${envConfig.STUrl}#/EditInvoice/${params.row.invoiceId}`;
      console.log("invoice st url :", invoiceUrl);
      window.open(invoiceUrl, "_blank");
    }
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value); // Update page size
    setPage(0); // Reset to the first page when page size changes
  };

  const handleAgreementDetailDialogClose = () => {
    setOpenAgreementDetailDialog(false);
  };

  const handleInvoiceDetailDialogClose = () => {
    setSelectedInvoiceId("");
    setOpenInvoiceDetailDialog(false);
  };

  const handleJBWIframeClaimDialogClose = (param) => {
    console.log("claim grid : ", param);
    setClaimDealerId();
    setClaimAgreementId();
    onUpdateClaim({ ...selectedClaim, ...param });
    setOpenJBWIframeClaimDialog(false);
  };

  const handleCreateClaim = (params) => () => {
    console.log("selected cliam create ", params);
    setSelectedClaim(params.row);
    setClaimDealerId(params.row.dealerId);
    setClaimAgreementId(params.row.jbwAgreementId);
    setOpenJBWIframeClaimDialog(true);
  };

  const handleDialogClose = (data) => {
    setDeleteDialogOpen(false);
    console.log("equipment delete index : ", data);
    if (data) {
      console.log(selectedClaims);
      onRejectClaim(selectedClaims);
    }
  };

  useEffect(() => {
    // setClaimsList(claimList);
    // if (tenant) {
    //   setClaimsList((oldvlu) => {
    //     return claimList.filter((cl) => cl.tenantId === tenant);
    //   });
    // }
    let filteredList = claimList;

    if (tenant) {
      filteredList = claimList.filter((cl) => cl.tenantId === tenant);
    }

    if (filterStatus === "Pending") {
      filteredList = filteredList.filter((cl) => !cl.jbwClaimId);
    } else if (filterStatus === "Completed") {
      filteredList = filteredList.filter((cl) => !!cl.jbwClaimId);
    }
    setClaimsList(filteredList);
    // setPage(0);
  }, [filterStatus, tenant, claimList]);

  const QuickSearchToolbar = () => {
    const handleSearchFocus = () => {
      if (filterStatus != "All") {
        setFilterStatus("All");
      }
    };
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Left-aligned Quick Filter */}
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <GridToolbarQuickFilter onFocus={handleSearchFocus} />
        </Box>
        <Box>
          {isAnyRowSelected && (
            <Button
              variant="contained"
              color="error"
              onClick={handleMultiDelete}
            >
              Ignore Selected Claims
            </Button>
          )}
        </Box>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={filterStatus}
            onChange={handleFilterChange}
            aria-label="Status Filter"
            name="status-filter"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              value="Pending"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value="Completed"
              control={<Radio />}
              label="Completed"
            />
            <FormControlLabel value="All" control={<Radio />} label="All" />
          </RadioGroup>
        </FormControl>

        {/* Center-aligned Status Filter */}
        {/* <FormControl sx={{ minWidth: 120 }}>
          <Select
            labelId="clam-status-label"
            value={filterStatus}
            onChange={handleFilterChange}
            label="Status Filter"
            sx={{
              border: "none", // Removes the border
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Removes the border for outlined style
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none", // Prevents border on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none", // Prevents border when focused
              },
            }}
          >
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
          </Select>
        </FormControl> */}

        {/* Right-aligned Page Size Control */}
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle1">Page Size:</Typography>
          <FormControl sx={{ minWidth: 75 }}>
            <Select
              labelId="page-size-label"
              value={pageSize}
              onChange={handlePageSizeChange}
              sx={{
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </GridToolbarContainer>
    );
  };

  const CustomFooter = () => (
    <GridFooterContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* Left-aligned Delete Button */}
      <Box sx={{ padding: "10px" }}>
        {isAnyRowSelected && (
          <Button variant="contained" color="error" onClick={handleMultiDelete}>
            Ignore Selected Claims
          </Button>
        )}
      </Box>

      {/* Right-aligned Pagination */}
      <GridPagination />
    </GridFooterContainer>
  );

  return (
    <>
      {/* <Box sx={{ mb: 2 }}>
        {isAnyRowSelected && (
          <Button variant="contained" color="error" onClick={handleMultiDelete}>
            Ignore Selected Claims
          </Button>
        )}
      </Box> */}
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        title="Ignore Claims"
        message="Are You Sure. Do you want to ignore the selected claims?"
      />
      <JBWClaimsIframDialog
        dialogOpen={openJBWIframeClaimDialog}
        onClose={handleJBWIframeClaimDialogClose}
        dealerId={claimDealerId}
        agreementId={claimAgreementId}
      />
      {selectedAgreementId && (
        <JBWAgreementsDetailDialog
          jbwAgreementId={selectedAgreementId}
          dialogOpen={openAgreementDetailDialog}
          onClose={handleAgreementDetailDialogClose}
        />
      )}
      {selectedInvoiceId && (
        <InvoiceDetailsDialog
          invoiceId={selectedInvoiceId}
          dialogOpen={openInvoiceDetailDialog}
          onClose={handleInvoiceDetailDialogClose}
        />
      )}
      {showLoading === true ? (
        <Box sx={{ height: "100px" }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <StyledDataGrid
          rows={claimsList}
          onCellClick={handleCellClick}
          getRowId={(row) => row.id}
          columns={columns}
          autoHeight
          getRowHeight={() => "auto"}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(model) => setPage(model.page)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          checkboxSelection
          isRowSelectable={(params) => !params.row.jbwClaimId}
          onRowSelectionModelChange={(selectionModel) => {
            setSelectedClaims(selectionModel);
            handleRowSelection(selectionModel); // Pass the selected IDs to handleRowSelection
          }}
          // getRowClassName={(params) => {
          //   return params.row.jbwAgreementId === null
          //     ? `super-app-theme--InActive`
          //     : `super-app-theme--Active`;
          // }}
          disableColumnFilter={true}
          disableColumnMenu={true}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: QuickSearchToolbar,
            footer: CustomFooter,
          }}
          sx={{
            "& .muidatagrid-colcelltitle ": {
              display: "block",
              textAlign: "center",
              width: "100%",
            },
            "& .MuiTablePagination-toolbar .MuiTablePagination-selectLabel": {
              display: "none", // Hides the "Rows per page" label
            },
            "& .MuiTablePagination-toolbar .MuiInputBase-root": {
              display: "none", // Hides the dropdown
            },
          }}
        />
      )}
    </>
  );
};

export default ClaimsGrid;
