import { Box, Button, Fab, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import TenantGrid from "./TenantGrid";
import { AuthContext } from "../AuthContext";
import TenantsDialog from "./TenantsDialog";
import RefreshIcon from "@mui/icons-material/Refresh";

const TenantsList = ({
  tenantList,
  updateTenantsList,
  onSelectedTenant,
  onRestTenant,
}) => {
  const { dealer, setOnboarding } = useContext(AuthContext);
  const [openEditTenant, setOpentEditTenant] = useState(false);
  const [editTenantData, setEditTenantData] = useState();
  const [selectedTenant, setSelectedTenant] = useState();

  let navigate = useNavigate();
  const newTenantHandler = () => {
    setOnboarding({ dealerId: dealer.dealerId, dealerName: dealer.dealerName });
    navigate("/home/onboarding/clientlocation", {
      state: { key: "tenantlist" },
    });
  };
  const handleEditTenant = (data) => {
    console.log("Edit tenant data :", data);
    setOpentEditTenant(true);
    setEditTenantData(data);
  };

  const handleCloseEditTenant = (data) => {
    if (data === "success") {
      updateTenantsList();
    }
    setOpentEditTenant(false);
  };

  const handleRestTenant = () => {
    updateTenantsList();
    onRestTenant();
    setSelectedTenant();
  };

  useEffect(() => {
    if (tenantList.length === 1) {
      setSelectedTenant(tenantList[0]);
      onSelectedTenant(tenantList[0].tenantId);
    }
  }, [tenantList]);

  const handleTenantSelected = (param) => {
    console.log("selected tenant :", param);
    onSelectedTenant(param?.tenantId);
    setSelectedTenant(param);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: 1,
        boxShadow: 4,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <TenantsDialog
        open={openEditTenant}
        tenantData={editTenantData}
        onClose={handleCloseEditTenant}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              maxWidth: "100%",
              padding: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6" component="h6">
              Tenants
              {/* <Typography
                variant="body2"
                component="div"
                sx={{ color: "#a8a8a8d4" }}
              >
                Showing 5 out of 5
              </Typography> */}
            </Typography>
            {tenantList.length > 1 && selectedTenant && (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body2" component="span">
                  Tenant Selected:{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    {selectedTenant?.tenantName
                      ? selectedTenant?.tenantName
                      : selectedTenant?.tenantId}
                  </Typography>{" "}
                  click to reset
                  <IconButton
                    aria-label="reset"
                    size="small"
                    color="secondary"
                    onClick={handleRestTenant}
                  >
                    <RefreshIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              </>
            )}

            <Box sx={{ flexGrow: 1 }} />
            <Box>
              {/* <Fab
                size="small"
                edge="end"
                aria-label="Reset Tenant"
                aria-haspopup="true"
                color="primary"
                onClick={handleRestTenant}
                sx={{ mr: 4 }}
              >
                <RefreshIcon style={{ fill: "white" }} fontSize="small" />
              </Fab> */}
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                sx={{
                  borderRadius: "50px",
                }}
                onClick={newTenantHandler}
              >
                Onboard New Tenant
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              padding: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TenantGrid
              tenantList={tenantList}
              editTenant={handleEditTenant}
              onSelectedTenant={handleTenantSelected}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TenantsList;
